import * as AuthActionTypes from '../actions/auth';
import * as UserActionTypes from '../../shell/redux/actions/user';

const initialState = {
  requestAccessStatus: {},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.INIT_SSE: {
      return {
        ...state,
      };
    }

    case UserActionTypes.ME_FAILED:
      return initialState;

    default:
      return state;
  }
}
