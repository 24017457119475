import * as CommonActionTypes from '../../shell/redux/actions/common';

export const INIT_SSE = 'INIT_SSE';

const users = {};

export function initSse(userId: number, callback?: (data: any) => void) {
  if (users[userId]) {
    return;
  }

  users[userId] = true;

  return async (dispatch) => {
    const sse = new EventSource('/api/v1/events');

    sse.onmessage = (message) => {
      const data = JSON.parse(message.data);

      callback?.(data);
      dispatch({
        type: CommonActionTypes.NOTIFY,
        notification: data,
      });
    };

    dispatch({
      type: INIT_SSE,
    });
  };
}
