import React from 'react';

class LoginPage extends React.Component {
  constructor() {
    super();
    window.location.href = '/api/v1/auth/login';
  }

  render() {
    return null;
  }
}

export default LoginPage;
